const CONFIGURED_RULE_TYPES = {
  PUREFI_KYC1: 0,
  PUREFI_KYC2: 1,
  PUREFI_KYC3: 2,
  PANTHER_KYC1: 3,
  PANTHER_KYC2: 4,
  PANTHER_KYC3: 5,
  KYC_AGE: 6,
  KYC_COUNTRY: 7,
  KYC_COUNTRY_AGE: 8,
  AML: 9,
  AML_KYC: 10,
  AML_OPTIONAL_KYC: 11,
};

const DEFAULT_RULE_TYPE_VALUES = {
  [CONFIGURED_RULE_TYPES.PUREFI_KYC1]: '776',
  [CONFIGURED_RULE_TYPES.PUREFI_KYC2]: '777',
  [CONFIGURED_RULE_TYPES.PUREFI_KYC3]: '778',
  [CONFIGURED_RULE_TYPES.PANTHER_KYC1]: '91',
  [CONFIGURED_RULE_TYPES.PANTHER_KYC2]: '92',
  [CONFIGURED_RULE_TYPES.PANTHER_KYC3]: '93',
  [CONFIGURED_RULE_TYPES.KYC_AGE]: '231',
  [CONFIGURED_RULE_TYPES.KYC_COUNTRY]: '131',
  [CONFIGURED_RULE_TYPES.KYC_COUNTRY_AGE]: '331',
  [CONFIGURED_RULE_TYPES.AML]: '431050',
  [CONFIGURED_RULE_TYPES.AML_KYC]: '731090',
  [CONFIGURED_RULE_TYPES.AML_OPTIONAL_KYC]: '631050090',
};

const RULE_TYPE_OPTIONS = [
  {
    label: 'PUREFI KYC 1',
    value: CONFIGURED_RULE_TYPES.PUREFI_KYC1,
  },
  {
    label: 'PUREFI KYC 2',
    value: CONFIGURED_RULE_TYPES.PUREFI_KYC2,
  },
  {
    label: 'PUREFI KYC 3',
    value: CONFIGURED_RULE_TYPES.PUREFI_KYC3,
  },
  {
    label: 'PANTHER KYC 1',
    value: CONFIGURED_RULE_TYPES.PANTHER_KYC1,
  },
  {
    label: 'PANTHER KYC 2',
    value: CONFIGURED_RULE_TYPES.PANTHER_KYC2,
  },
  {
    label: 'PANTHER KYC 3',
    value: CONFIGURED_RULE_TYPES.PANTHER_KYC3,
  },
  {
    label: 'KYC + AGE',
    value: CONFIGURED_RULE_TYPES.KYC_AGE,
  },
  {
    label: 'KYC + COUNTRY',
    value: CONFIGURED_RULE_TYPES.KYC_COUNTRY,
  },
  {
    label: 'KYC + COUNTRY + AGE',
    value: CONFIGURED_RULE_TYPES.KYC_COUNTRY_AGE,
  },
  {
    label: 'AML',
    value: CONFIGURED_RULE_TYPES.AML,
  },
  {
    label: 'AML + KYC',
    value: CONFIGURED_RULE_TYPES.AML_KYC,
  },
  {
    label: 'AML + OPTIONAL KYC (Type 2)',
    value: CONFIGURED_RULE_TYPES.AML_OPTIONAL_KYC,
  },
];

const DEFAULT_RULE_TYPE = CONFIGURED_RULE_TYPES.PUREFI_KYC2;

export {
  CONFIGURED_RULE_TYPES,
  DEFAULT_RULE_TYPE_VALUES,
  RULE_TYPE_OPTIONS,
  DEFAULT_RULE_TYPE,
};
